.menu-bar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	background: rgba(244, 213, 245, 66%);
	backdrop-filter: blur(5px);
	color: #1c1d1f;
	width: 100%;
	padding: 0.05rem;
	height: 1.6rem;
	box-sizing: border-box;
	font-size: 0.875rem;
	font-weight: 500;

	@media (max-width: 768px) {
		height: 1.6rem;
		padding: 0.05rem;
		font-size: 0.875rem;
	}
}

.menu-bar img {
	height: 70%;
	padding: 0.1rem;
  }
  
  .menu-bar .app-menus > div:nth-child(2) {
	font-weight: 700;
  }
  
  .menu-bar .app-menus > div .apple {
	height: 70%;
	padding: 0.1rem;
  }
  
  .menu-bar .app-menus > div {
	margin-left: 1.45rem;
  }
  
  .menu-bar .right-side {
	float: right;
  }
  
  .menu-bar .right-side > div {
	margin-right: 1rem;
  }
  
  .menu-bar .right-side > div img {
	height: 50%;
	padding: 0.25rem;
	opacity: 0.8;
  }
  
  .menu-bar .right-side > div:nth-child(4) img {
	height: 70%;
	padding: 0.2rem;
  }
  
  .menu-bar .right-side > div:last-child {
	margin-right: 1.45rem;
	font-size: 0.875rem;
  }
  
  .menu-bar .right-side > div .time {
	margin-left: 0.4em;
  }
  
  .menu-bar .app-menus,
  .menu-bar .right-side {
	height: 100%;
	display: flex;
	align-items: center;
  }
  
  .menu-bar .app-menus > div,
  .menu-bar .right-side > div {
	height: min-content;
	display: inline-block;
	vertical-align: top;
  }
  
  .menu-bar .img-container {
	height: 100% !important;
	display: flex !important;
	align-items: center;
  }

@media (max-width: 768px) {
  .menu-bar .right-side > div {
    margin-right: 0.5rem;
  }

  .menu-bar .right-side > div:last-child {
    margin-right: 0.5rem;
  }

  .menu-bar .right-side > div img {
    padding: 0.1rem;
  }

  .menu-bar .right-side > div:nth-child(4) img {
    padding: 0.1rem;
  }

  .menu-bar .right-side > div .time {
    margin-left: 0.2em;
  }
}
